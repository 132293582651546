<template>
  <div>
    <section>
      <b-jumbotron ref="section1" :style="backgroundImageStyle" class="px-3">
        <div class="my-5">
          <b-row>
            <b-col cols="12" class="p-0 mb-5">
              <h1 class="display-4 font-weight-bolder">
                About Ristic Cars
              </h1>
    
              <h4 class="text-white font-weight-bold">
                The story behind
              </h4>
            </b-col>

            <b-col cols="12" md="6" class="pl-0 mb-1">
              <b-card-text class="text-white">
                Lorem ipsum dolor sit amet consectetur. Tincidunt purus tellus justo a. Volutpat elit nibh diam morbi. Etiam fames nisi habitasse dictum. Lacus cursus sed consectetur et dolor lectus. Nunc pretium pulvinar feugiat viverra malesuada. Gravida justo accumsan tempus cras amet condimentum velit egestas.
Velit nunc dignissim tellus dui pretium parturient. Morbi malesuada cras nunc non scelerisque tristique quis dui proin. In mattis adipiscing nunc lacinia. Pellentesque nulla convallis pharetra lorem enim quis turpis id. Dolor a sapien tellus elit nec. Ipsum eu ultricies egestas sit nisl sed vel. Sed eu mattis fermentum diam tristique eleifend ut aenean. Quisque nam elementum vestibulum arcu mi sed malesuada.
Id sapien cursus consectetur arcu mattis id id. Sed eu tortor id hac elit maecenas cursus turpis. Integer pulvinar vulputate tincidunt metus in tempus non nam ornare. Elementum dignissim eget luctus blandit augue augue faucibus id. Pellentesque urna orci aliquam suspendisse neque. Amet purus erat venenatis malesuada amet nisl. Nulla viverra euismod fusce eleifend eu integer nam parturient ultricies. Arcu amet pellentesque porta sagittis amet egestas consectetur in ridiculus. Facilisi ligula dapibus amet cursus eget nec vestibulum amet elementum. Hac ornare ac in euismod at fringilla. Augue posuere semper proin felis nullam. Massa ultricies bibendum aliquet a eget.
              </b-card-text>
            </b-col>

            <b-col cols="12" md="6" class="pl-0 mb-1">
              <b-card-text class="text-white">
                Lorem ipsum dolor sit amet consectetur. Tincidunt purus tellus justo a. Volutpat elit nibh diam morbi. Etiam fames nisi habitasse dictum. Lacus cursus sed consectetur et dolor lectus. Nunc pretium pulvinar feugiat viverra malesuada. Gravida justo accumsan tempus cras amet condimentum velit egestas.
Velit nunc dignissim tellus dui pretium parturient. Morbi malesuada cras nunc non scelerisque tristique quis dui proin. In mattis adipiscing nunc lacinia. Pellentesque nulla convallis pharetra lorem enim quis turpis id. Dolor a sapien tellus elit nec. Ipsum eu ultricies egestas sit nisl sed vel. Sed eu mattis fermentum diam tristique eleifend ut aenean. Quisque nam elementum vestibulum arcu mi sed malesuada.
Id sapien cursus consectetur arcu mattis id id. Sed eu tortor id hac elit maecenas cursus turpis. Integer pulvinar vulputate tincidunt metus in tempus non nam ornare. Elementum dignissim eget luctus blandit augue augue faucibus id. Pellentesque urna orci aliquam suspendisse neque. Amet purus erat venenatis malesuada amet nisl. Nulla viverra euismod fusce eleifend eu integer nam parturient ultricies. Arcu amet pellentesque porta sagittis amet egestas consectetur in ridiculus. Facilisi ligula dapibus amet cursus eget nec vestibulum amet elementum. Hac ornare ac in euismod at fringilla. Augue posuere semper proin felis nullam. Massa ultricies bibendum aliquet a eget.
              </b-card-text>
            </b-col>
          </b-row>
        </div>
      </b-jumbotron>
    </section>

    <section class="my-5">
      <div class="overflow-hidden">
        <why-choose-us />
      </div>
    </section>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BImg,
  BLink,
  BOverlay,
  BCardText,
  BJumbotron,
} from 'bootstrap-vue';

import HeroImage from '@/assets/images/public/hero-2.png';
import WhyChooseUs from '@/@core/components/shared/WhyChooseUs.vue';

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BLink,
    BOverlay,
    BCardText,
    BJumbotron,

    WhyChooseUs
  },
  data() {
    return {
      loading: false,
      heroImage: HeroImage,
    }
  },
  computed: {
    backgroundImageStyle() {
      return {
        backgroundImage: `url(${this.heroImage})`,
        backgroundSize: 'cover', // This ensures the image covers the div
        backgroundPosition: 'center', // Center the image
        backgroundRepeat: 'no-repeat', // Prevent the image from repeating
        borderRadius: 0,
      };
    },
  },
  methods: {
  }
}
</script>

