<template>
  <b-row no-gutters>
    <b-col md="5" bg-variant="light-primary">
      <b-card-img :src="require(`@/assets/images/why-us/car-2.png`)" alt="Image" class="rounded-0" />
    </b-col>

    <b-col md="7" class="">
      <b-card-body class="">
        <h1 class="font-weight-bolder text-dark mb-3 display-5">
          Why choose us
        </h1>

        <b-row v-for="(qualities, i) in ourQualities" :key="i" no-gutters class="mb-3">
          <b-col cols="12" md="1">
            <div class="bg-primary d-flex align-items-center justify-content-center rounded-circle" style="height: 40px; width: 40px">
              <h2 class="font-weight-bolder text-white m-0 p-0">{{ i + 1 }}</h2>
            </div>
          </b-col>

          <b-col cols="12" md="11">
            <h4 class="font-weight-bolder text-dark mb-1">
              {{ qualities.title }}
            </h4>

            <b-card-text class="text-muted">
              {{ qualities.description }}
            </b-card-text>
          </b-col>
        </b-row>
      </b-card-body>
    </b-col>
  </b-row>
</template>

<script>
  import {
    BRow, 
    BCol,
    BCardImg,
    BCardBody,
    BCardText,
  } from 'bootstrap-vue';

  export default {
    components: {
      BRow, 
      BCol,
      BCardImg,
      BCardBody,
      BCardText,
    },
    data() {
      return {
        ourQualities: [
          {
            title: 'Convenient',
            description: 'Lorem ipsum dolor sit amet consectetur. Sed nisi risus viverra amet non. Diam tristique ac diam fames sollicitudin. Arcu enim mauris tristique tempor. Lectus faucibus nec eu quam aliquet et. Mauris sapien commodo.',
          },
          {
            title: 'Affordable',
            description: 'Lorem ipsum dolor sit amet consectetur. Sed nisi risus viverra amet non. Diam tristique ac diam fames sollicitudin. Arcu enim mauris tristique tempor. Lectus faucibus nec eu quam aliquet et. Mauris sapien commodo.',
          },
          {
            title: 'Trained Drivers',
            description: 'Lorem ipsum dolor sit amet consectetur. Sed nisi risus viverra amet non. Diam tristique ac diam fames sollicitudin. Arcu enim mauris tristique tempor. Lectus faucibus nec eu quam aliquet et. Mauris sapien commodo.',
          },
          {
            title: 'Safety',
            description: 'Lorem ipsum dolor sit amet consectetur. Sed nisi risus viverra amet non. Diam tristique ac diam fames sollicitudin. Arcu enim mauris tristique tempor. Lectus faucibus nec eu quam aliquet et. Mauris sapien commodo.',
          },
        ],
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>
